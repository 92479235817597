<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import DatePickerCommon from '@/components/Select/date/main.vue';
import MultiSelectCommon from '@/components/Select/multi/main.vue';

import { authComputed, checkPermission, keyMaster, masterComputed, masterMethods, prizeMethods } from '@/state/helpers';
import { required, maxLength } from 'vuelidate/lib/validators';
import { showMessage } from '@/utils/messages';
import { regexNumber } from '@/utils/format';
import { handleValidate } from './validate';
import { InputText } from '@/components/Input';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: '賞品新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        MultiSelectCommon,
        Layout,
        Footer,
        DatePickerCommon,
        InputText
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            typeObject: {},
            noteGift: '',
            digitalObject: {},
            prizeLessObject: { id: '', value: '' },
            form: {
                digital_gifts: {},
                type: {
                    id: ''
                },
                campaign: null,
                name: '',
                quantity: '',
                quantity_received: '',
                coupon_value: '',
                scheduled_arrived_at: '',
                scheduled_arrived_at_y: '',
                scheduled_arrived_at_m: '',
                scheduled_arrived_at_d: '',
                is_packaging_photo_required: '1',
                expiration_date: '',
                expiration_date_y: '',
                expiration_date_m: '',
                expiration_date_d: '',
                warehousing_date: '',
                warehousing_date_y: '',
                warehousing_date_m: '',
                warehousing_date_d: '',
                shipping_date: '',
                shipping_date_d: '',
                shipping_date_m: '',
                shipping_date_y: '',
                warehousing_quantity: '',
                shipment_quantity: '',
                remaining_quantity: '',
                notification: '',
                prize_less_status: {
                    id: ''
                },
                reconfirmation_schedule: '',
                instructions_regarding_prizes: '',
                locator_number: '',
                month_start_fee_apply: '',
                storage_material: '',
                boxes_number: '',
                delivery_company: '',
                tracking_number: '',
                number_of_applications: {
                    total: '',
                    postcard_mail: '',
                    web: '',
                    palnavi: '',
                    sns: '',
                    twitter: '',
                    instagram: '',
                    line: '',
                    others: '',
                    invalid: ''
                },
                number_of_inquiries: {
                    total: '',
                    tel: '',
                    email: '',
                    number_of_cases: '',
                    irregular: '',
                    irregular_content: ''
                }
            },
            listDataMasterCampaigns: [],
            listDataMasterPrizeGift: [],
            listDataMasterGiftCategory: [],
            listDataMasterGiftDigital: [],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            customFormatDate: 'YYYY-MM-DD',
            customFormatMonth: 'YYYY-MM',
            prizeReturn: 0,
            configScheduledArrivedAt: {
                error: false,
                errorText: ''
            },
            configWarehousingDate: {
                error: false,
                errorText: ''
            },
            configShippingDate: {
                error: false,
                errorText: ''
            }
        };
    },
    computed: {
        ...masterComputed,
        ...authComputed
    },
    validations: {
        noteGift: { maxLength: maxLength(20) },
        form: {
            campaign: { required },
            name: { required, maxLength: maxLength(255) },
            quantity: { maxLength: maxLength(20) },
            expiration_date: { maxLength: maxLength(255) },
            warehousing_quantity: { maxLength: maxLength(20) },
            shipment_quantity: { maxLength: maxLength(20) },
            remaining_quantity: { maxLength: maxLength(20) },
            notification: { maxLength: maxLength(255) },
            reconfirmation_schedule: { maxLength: maxLength(255) },
            instructions_regarding_prizes: { maxLength: maxLength(255) },
            locator_number: { maxLength: maxLength(255) },
            month_start_fee_apply: { maxLength: maxLength(255) },
            storage_material: { maxLength: maxLength(255) },
            boxes_number: { maxLength: maxLength(255) },
            delivery_company: { maxLength: maxLength(255) },
            tracking_number: { maxLength: maxLength(255) },
            is_packaging_photo_required: { required },
            number_of_applications: {
                postcard_mail: { maxLength: maxLength(20) },
                web: { maxLength: maxLength(20) },
                palnavi: { maxLength: maxLength(20) },
                sns: { maxLength: maxLength(20) },
                twitter: { maxLength: maxLength(20) },
                instagram: { maxLength: maxLength(20) },
                line: { maxLength: maxLength(20) },
                others: { maxLength: maxLength(20) },
                invalid: { maxLength: maxLength(20) }
            },
            number_of_inquiries: {
                tel: { maxLength: maxLength(20) },
                email: { maxLength: maxLength(20) },
                number_of_cases: { maxLength: maxLength(20) },
                irregular: { maxLength: maxLength(20) }
            }
        }
    },
    watch: {
        typeObject: function (newData) {
            if (newData) {
                this.form.type.id = newData.id;
            } else {
                this.form.type.id = '';
            }
        },
        prizeLessObject: function (newData) {
            if (newData) {
                if (!this.form.prize_less_status) this.form.prize_less_status = {};
                this.form.prize_less_status.id = newData ? newData.id : '';
            } else {
                this.form.prize_less_status.id = '';
            }
        },
        'form.warehousing_quantity': function (newName) {
            if (newName) {
                let shipment_quantity = this.form.shipment_quantity ? parseInt(this.form.shipment_quantity) : 0;
                let warehousing_quantity = this.form.warehousing_quantity ? parseInt(this.form.warehousing_quantity) : 0;
                this.form.remaining_quantity = warehousing_quantity - (shipment_quantity + this.prizeReturn);
            }
        },
        'form.shipment_quantity': function (newName) {
            if (newName) {
                let shipment_quantity = this.form.shipment_quantity ? parseInt(this.form.shipment_quantity) : 0;
                let warehousing_quantity = this.form.warehousing_quantity ? parseInt(this.form.warehousing_quantity) : 0;
                this.form.remaining_quantity = warehousing_quantity - (shipment_quantity + this.prizeReturn);
            }
        },
        'form.expiration_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.form.expiration_date_y = date[0];
                this.form.expiration_date_m = date[1];
                this.form.expiration_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'form.number_of_applications.postcard_mail': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.web': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.palnavi': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.sns': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.twitter': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.instagram': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.line': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.others': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.invalid': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_inquiries.tel': function () {
            this.updateTotalNumberOfInquiries();
        },
        'form.number_of_inquiries.email': function () {
            this.updateTotalNumberOfInquiries();
        },
        'form.number_of_inquiries.number_of_cases': function () {
            this.updateTotalNumberOfInquiries();
        },
        'form.number_of_inquiries.irregular': function () {
            this.updateTotalNumberOfInquiries();
        }
    },
    beforeRouteUpdate(to, from, next) {
        // Xác định nếu có tham số id trong đường dẫn mới
        const queryId = to.query.id;
        if (!queryId) {
            this.resetForm();
        }
        // Gọi next để tiếp tục chuyển route
        next();
    },
    mounted() {
        this.getListMasterPrizeGift();
        this.getListMasterClient();
        this.getListMasterType();
        this.getListMasterPrizeGiftDigital();

        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
        }
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...prizeMethods,
        resetForm() {
            this.form = {
                title: '',
                campaign: null,
                name: '',
                quantity: '',
                scheduled_arrived_at: '',
                scheduled_arrived_at_y: '',
                scheduled_arrived_at_m: '',
                scheduled_arrived_at_d: '',
                is_packaging_photo_required: '1',
                expiration_date: '',
                expiration_date_y: '',
                expiration_date_m: '',
                expiration_date_d: '',
                warehousing_date: '',
                warehousing_date_y: '',
                warehousing_date_m: '',
                warehousing_date_d: '',
                shipping_date: '',
                shipping_date_y: '',
                shipping_date_m: '',
                shipping_date_d: '',
                warehousing_quantity: '',
                shipment_quantity: '',
                remaining_quantity: '',
                notification: '',
                prize_less_status: { id: '' },
                reconfirmation_schedule: '',
                instructions_regarding_prizes: '',
                locator_number: '',
                month_start_fee_apply: '',
                storage_material: '',
                boxes_number: '',
                delivery_company: '',
                tracking_number: '',
                number_of_applications: {
                    total: '',
                    postcard_mail: '',
                    web: '',
                    palnavi: '',
                    sns: '',
                    twitter: '',
                    instagram: '',
                    line: '',
                    invalid: ''
                },
                number_of_inquiries: {
                    total: '',
                    tel: '',
                    email: '',
                    number_of_cases: '',
                    irregular: '',
                    irregular_content: ''
                }
            };
        },

        updateTotalNumberOfApplication() {
            const postcardMail = parseInt(this.form.number_of_applications.postcard_mail) || 0;
            const web = parseInt(this.form.number_of_applications.web) || 0;
            const palnavi = parseInt(this.form.number_of_applications.palnavi) || 0;
            const sns = parseInt(this.form.number_of_applications.sns) || 0;
            const twitter = parseInt(this.form.number_of_applications.twitter) || 0;
            const instagram = parseInt(this.form.number_of_applications.instagram) || 0;
            const line = parseInt(this.form.number_of_applications.line) || 0;
            const others = parseInt(this.form.number_of_applications.others) || 0;
            const invalid = parseInt(this.form.number_of_applications.invalid) || 0;
            const total = postcardMail + web + palnavi + sns + twitter + instagram + line + others + invalid;
            this.form.number_of_applications.total = total.toString();
        },
        updateTotalNumberOfInquiries() {
            const tel = parseInt(this.form.number_of_inquiries.tel) || 0;
            const email = parseInt(this.form.number_of_inquiries.email) || 0;
            const number_of_cases = parseInt(this.form.number_of_inquiries.number_of_cases) || 0;
            const irregular = parseInt(this.form.number_of_inquiries.irregular) || 0;

            const total = tel + email + number_of_cases + irregular;
            this.form.number_of_inquiries.total = total.toString();
        },
        getDetail(id) {
            this.loading = true;
            this.detailPrize(id).then((data) => {
                if (!data.type) {
                    data.type = {
                        id: ''
                    };
                }
                this.form = JSON.parse(JSON.stringify(data));

                this.form.number_of_applications = data.number_of_applications
                    ? data.number_of_applications
                    : {
                          total: '',
                          postcard_mail: '',
                          web: '',
                          palnavi: '',
                          sns: '',
                          twitter: '',
                          instagram: '',
                          line: '',
                          others: '',
                          invalid: ''
                      };
                this.form.number_of_inquiries = data.number_of_inquiries
                    ? data.number_of_inquiries
                    : {
                          total: '',
                          tel: '',
                          email: '',
                          number_of_cases: '',
                          irregular: '',
                          irregular_content: ''
                      };
                this.typeObject = this.form.type;
                if (this.form.digital_gifts[0]) this.digitalObject = this.form.digital_gifts[0];
                if (this.form.digital_gifts[0] && this.form.digital_gifts[0].extension)
                    this.noteGift = this.form.digital_gifts[0].extension.value_note;
                this.prizeLessObject = this.form.prize_less_status ? this.form.prize_less_status : { id: '' };
                this.prizeReturn = this.form.return_items_count ? this.form.return_items_count : 0;
                this.form.title = data.event_prizes.title ?? '';
                this.form.campaign = data.event_prizes.campaign.name ?? null;
                this.loading = false;
            });
        },
        getListMasterPrizeGift() {
            this.listMaster(keyMaster['prize.prize_less_status']).then((data) => {
                this.listDataMasterPrizeGift = data;
            });
        },
        getListMasterClient() {
            this.listMaster('all/campaigns').then((data) => {
                this.listDataMasterCampaigns = data;
            });
        },
        getListMasterType() {
            this.listMaster('campaign.gift_category').then((data) => {
                this.listDataMasterGiftCategory = data;
            });
        },
        getListMasterPrizeGiftDigital() {
            this.listMaster('campaign.prize.digital_gift').then((data) => {
                this.listDataMasterGiftDigital = data;
            });
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            this.$v.$touch();
            let check = handleValidate(this.form, this.configScheduledArrivedAt, this.configWarehousingDate, this.configShippingDate);
            if (this.$v.form.$invalid || this.$v.noteGift.$invalid || !check) {
                return;
            } else {
                this.register();
            }
        },
        register() {
            if (this.loading) return;
            if (this.form.name) {
                this.loading = true;
                let obj = Object.assign({}, this.form);
                if (obj.expiration_date_y && obj.expiration_date_m && obj.expiration_date_d)
                    obj.expiration_date = obj.expiration_date_y + '-' + obj.expiration_date_m + '-' + obj.expiration_date_d;
                obj.warehousing_quantity = obj.warehousing_quantity && obj.warehousing_quantity.length <= 0 ? 0 : Number(obj.warehousing_quantity);
                obj.remaining_quantity = obj.remaining_quantity && obj.remaining_quantity.length <= 0 ? 0 : Number(obj.remaining_quantity);
                obj.shipment_quantity = obj.shipment_quantity && obj.shipment_quantity.length <= 0 ? 0 : Number(obj.shipment_quantity);
                obj.quantity = obj.quantity && obj.quantity.length <= 0 ? 0 : Number(obj.quantity);
                obj.digital_gifts = {};
                obj.campaign_event_prize = {
                    id: this.form.event_prizes.id ?? null
                };
                if (this.digitalObject.id) {
                    obj.digital_gifts[this.digitalObject.id] = {
                        extension: {
                            value_note: this.noteGift
                        }
                    };
                }

                if (this.$route.query.id) {
                    this.updatePrize(obj).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, '賞品が更新されました。');
                        }
                        if (data.code == 422) {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                        this.loading = false;
                    });
                } else {
                    this.registerPrize(obj).then((data) => {
                        if (data.code == 200) {
                            this.$router
                                .push({
                                    path: '/prize/form/view',
                                    query: {
                                        id: data.data.id
                                    }
                                })
                                .then(() => {
                                    showMessage(data.code, this.$bvToast, '賞品が登録されました。');
                                });
                        }
                        if (data.code == 422) {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                        this.loading = false;
                    });
                }
            }
        },

        preventInputE(event) {
            // Ngăn chặn ký tự "e" từ được nhập vào ô input
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
        },
        inputQuantity() {
            this.form.quantity = regexNumber(this.form.quantity);
        },
        inputNumberCouponValue() {
            this.form.coupon_value = regexNumber(this.form.coupon_value);
        },

        inputNumber(key, key1) {
            if (key1) this.form[key][key1] = regexNumber(this.form[key][key1]);
            else this.form[key] = regexNumber(this.form[key]);
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card form--sroll mb-0">
                    <div class="card-header">
                        <h5 class="title--screen">{{ $route.query.id ? '賞品関連情報' : '賞品新規登録' }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-8">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品名</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`賞品名`"
                                                :model.sync="form.name"
                                                :config="{
                                                    error: false
                                                }"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品タイトル</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`賞品タイトル`"
                                                :model.sync="form.title"
                                                :config="{
                                                    error: false
                                                }"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">キャンペーン名 <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <div>
                                                <InputText
                                                    :id="`キャンペーン名`"
                                                    :model.sync="form.campaign"
                                                    :config="{
                                                        error: false
                                                    }"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">種類</label>
                                        <div class="col-sm-8">
                                            <div>
                                                <MultiSelectCommon
                                                    :id="`select-type-object`"
                                                    :value.sync="typeObject"
                                                    :options="listDataMasterGiftCategory"
                                                    :config="{
                                                        error: false,
                                                        isRemove: true,
                                                        trackBy: 'id',
                                                        label: 'value'
                                                    }"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="form-group row"
                                        v-if="
                                            typeObject.value == '通常の金券' ||
                                            typeObject.value == '電子版の金券' ||
                                            typeObject.value == '旅行・宿泊・ﾚｼﾞｬｰへの招待券' ||
                                            typeObject.value == 'ファッション・高級ブランド・貴金属品'
                                        "
                                    >
                                        <label class="col-sm-4" for="validationCustom05">金額入力</label>
                                        <div class="col-sm-8">
                                            <div>
                                                <InputText
                                                    :id="`coupon_value`"
                                                    :model.sync="form.coupon_value"
                                                    :config="{
                                                        maxlength: 10,
                                                        isNumber: true,
                                                        error: false
                                                    }"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row" v-if="typeObject.value == 'デジタルギフト'">
                                        <label class="col-sm-4" for="validationCustom05">デジタルギフト ★ </label>
                                        <div class="col-sm-8">
                                            <div>
                                                <MultiSelectCommon
                                                    :id="`select-digital-object`"
                                                    :value.sync="digitalObject"
                                                    :options="listDataMasterGiftDigital"
                                                    :config="{
                                                        error: false,
                                                        isRemove: true,
                                                        trackBy: 'id',
                                                        label: 'value'
                                                    }"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row" v-if="digitalObject.id">
                                        <label class="col-sm-4" for="validationCustom05">{{ digitalObject.value }}★ </label>
                                        <div class="col-sm-8">
                                            <div class="d-flex align-items-center">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="noteGift"
                                                    :class="{
                                                        'is-invalid': submitted && $v.noteGift.$error
                                                    }"
                                                />
                                                <span style="width: 50px; margin-left: 9px">円分</span>
                                            </div>

                                            <span
                                                :class="{
                                                    'is-invalid': submitted && $v.noteGift.$error
                                                }"
                                            ></span>
                                            <div v-if="submitted && $v.noteGift.$error" class="invalid-feedback">
                                                <span v-if="!$v.noteGift.maxLength">{{
                                                    $t('validateField.max20', {
                                                        field: digitalObject.value
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">数量</label>
                                        <div class="col-sm-4">
                                            <InputText
                                                :id="`quantity`"
                                                :model.sync="form.quantity"
                                                :config="{
                                                    isNumber: true,
                                                    maxlength: 10,
                                                    error: false
                                                }"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">入庫数（顧客から）</label>
                                        <div class="col-sm-4">
                                            <InputText
                                                :id="`quantity_received`"
                                                :model.sync="form.quantity_received"
                                                :config="{
                                                    isNumber: true,
                                                    maxlength: 10,
                                                    error: false
                                                }"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">入庫予定日</label>
                                        <div class="col-sm-8">
                                            <DatePickerCommon
                                                :year.sync="form.scheduled_arrived_at_y"
                                                :month.sync="form.scheduled_arrived_at_m"
                                                :day.sync="form.scheduled_arrived_at_d"
                                                :full_date.sync="form.scheduled_arrived_at"
                                                :config.sync="configScheduledArrivedAt"
                                            >
                                                <div v-if="configScheduledArrivedAt.error" class="invalid-feedback d-block">
                                                    <span>{{ configScheduledArrivedAt.errorText }}</span>
                                                </div>
                                            </DatePickerCommon>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">梱包写真必要有無</label>
                                        <div class="col-sm-4">
                                            <div class="d-flex">
                                                <div class="custom-control custom-radio mr-4">
                                                    <input
                                                        type="radio"
                                                        v-model="form.is_packaging_photo_required"
                                                        id="is_packaging_photo_required1"
                                                        value="1"
                                                        class="custom-control-input"
                                                    />
                                                    <label for="is_packaging_photo_required1" class="custom-control-label">あり </label>
                                                </div>
                                                <div class="custom-control custom-radio mr-4">
                                                    <input
                                                        type="radio"
                                                        v-model="form.is_packaging_photo_required"
                                                        id="is_packaging_photo_required"
                                                        value="0"
                                                        class="custom-control-input"
                                                    />
                                                    <label for="is_packaging_photo_required" class="custom-control-label">なし</label>
                                                </div>
                                            </div>
                                            <span
                                                :class="{
                                                    'is-invalid': submitted && $v.form.is_packaging_photo_required.$error
                                                }"
                                            ></span>
                                            <div v-if="submitted && $v.form.is_packaging_photo_required.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.is_packaging_photo_required.required">{{
                                                    $t('validateField.required', {
                                                        field: '梱包写真必要有無'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05"
                                            >賞味期限/消費期限<br />
                                            <small>(食品の場合)</small></label
                                        >
                                        <div class="col-sm-8">
                                            <input
                                                type="text"
                                                v-model="form.expiration_date"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.expiration_date.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.expiration_date.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.expiration_date.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: '賞味期限/消費期限'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">入庫日</label>
                                        <div class="col-sm-8">
                                            <DatePickerCommon
                                                :year.sync="form.warehousing_date_y"
                                                :month.sync="form.warehousing_date_m"
                                                :day.sync="form.warehousing_date_d"
                                                :full_date.sync="form.warehousing_date"
                                                :config.sync="configWarehousingDate"
                                            >
                                                <div v-if="configWarehousingDate.error" class="invalid-feedback d-block">
                                                    <span>{{ configWarehousingDate.errorText }}</span>
                                                </div>
                                            </DatePickerCommon>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">入庫数</label>
                                        <div class="col-sm-4">
                                            <input
                                                type="number"
                                                class="form-control"
                                                v-model="form.warehousing_quantity"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.warehousing_quantity.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.warehousing_quantity.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.warehousing_quantity.maxLength">{{
                                                    $t('validateField.max20', {
                                                        field: '入庫数'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送日</label>
                                        <div class="col-sm-8">
                                            <DatePickerCommon
                                                :year.sync="form.shipping_date_y"
                                                :month.sync="form.shipping_date_m"
                                                :day.sync="form.shipping_date_d"
                                                :full_date.sync="form.shipping_date"
                                                :config.sync="configShippingDate"
                                            >
                                                <div v-if="configShippingDate.error" class="invalid-feedback d-block">
                                                    <span>{{ configShippingDate.errorText }}</span>
                                                </div>
                                            </DatePickerCommon>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送数</label>
                                        <div class="col-sm-4">
                                            <input
                                                type="number"
                                                class="form-control"
                                                v-model="form.shipment_quantity"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.shipment_quantity.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.shipment_quantity.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.shipment_quantity.maxLength">{{
                                                    $t('validateField.max20', {
                                                        field: '発送数'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">残数</label>
                                        <div class="col-sm-4">
                                            <input
                                                type="number"
                                                class="form-control"
                                                v-model="form.remaining_quantity"
                                                disabled
                                                :class="{
                                                    'is-invalid': submitted && $v.form.remaining_quantity.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.remaining_quantity.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.remaining_quantity.maxLength">{{
                                                    $t('validateField.max20', {
                                                        field: '残数'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">連絡事項</label>
                                        <div class="col-sm-8">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="form.notification"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.notification.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.notification.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.notification.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: '連絡事項'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品あまりステータス</label>
                                        <div class="col-sm-8">
                                            <MultiSelectCommon
                                                :id="`select-prizeLess-object`"
                                                :value.sync="prizeLessObject"
                                                :options="listDataMasterPrizeGift"
                                                :config="{
                                                    error: false,
                                                    isRemove: true,
                                                    trackBy: 'id',
                                                    label: 'value'
                                                }"
                                                @remove="
                                                    () => {
                                                        prizeLessObject = { id: '', value: '' };
                                                    }
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">再確認日程</label>
                                        <div class="col-sm-8">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="form.reconfirmation_schedule"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.reconfirmation_schedule.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.reconfirmation_schedule.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.reconfirmation_schedule.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: '再確認日程'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品あまり対応指示項目</label>
                                        <div class="col-sm-8">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="form.instructions_regarding_prizes"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.instructions_regarding_prizes.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.instructions_regarding_prizes.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.instructions_regarding_prizes.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: '賞品あまり対応指示項目'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">保管棚番号</label>
                                        <div class="col-sm-8">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="form.locator_number"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.locator_number.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.locator_number.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.locator_number.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: '保管棚番号'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">保管料がかかり始める月</label>
                                        <div class="col-sm-8">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="form.month_start_fee_apply"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.month_start_fee_apply.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.month_start_fee_apply.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.month_start_fee_apply.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: '保管料がかかり始める月'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">保管料</label>
                                        <div class="col-sm-8">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="form.storage_material"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.storage_material.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.storage_material.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.storage_material.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: '保管料'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">箱数</label>
                                        <div class="col-sm-8">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="form.boxes_number"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.boxes_number.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.boxes_number.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.boxes_number.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: '箱数'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">配送業者</label>
                                        <div class="col-sm-8">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="form.delivery_company"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.delivery_company.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.delivery_company.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.delivery_company.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: '配送業者'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">追跡番号</label>
                                        <div class="col-sm-8">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="form.tracking_number"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.tracking_number.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.tracking_number.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.tracking_number.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: '追跡番号'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">応募数</label>
                                        <div class="col-sm-8">
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">総応募数</label>
                                                <div class="col-sm-5">
                                                    <input type="text" disabled class="form-control" v-model="form.number_of_applications.total" />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">ハガキ・郵送</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        @keypress="preventInputE"
                                                        @input="inputNumber('number_of_applications', 'postcard_mail')"
                                                        class="form-control"
                                                        v-model="form.number_of_applications.postcard_mail"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_applications.postcard_mail.$error
                                                        }"
                                                    />
                                                    <div
                                                        v-if="submitted && $v.form.number_of_applications.postcard_mail.$error"
                                                        class="invalid-feedback"
                                                    >
                                                        <span v-if="!$v.form.number_of_applications.postcard_mail.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'ハガキ・郵送'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">web</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        @keypress="preventInputE"
                                                        class="form-control"
                                                        @input="inputNumber('number_of_applications', 'web')"
                                                        v-model="form.number_of_applications.web"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_applications.web.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.number_of_applications.web.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.number_of_applications.web.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'Web'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">パルなび</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        @keypress="preventInputE"
                                                        class="form-control"
                                                        @input="inputNumber('number_of_applications', 'palnavi')"
                                                        v-model="form.number_of_applications.palnavi"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_applications.palnavi.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.number_of_applications.palnavi.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.number_of_applications.palnavi.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'パルなび'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">SNS</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        @keypress="preventInputE"
                                                        class="form-control"
                                                        @input="inputNumber('number_of_applications', 'sns')"
                                                        v-model="form.number_of_applications.sns"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_applications.sns.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.number_of_applications.sns.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.number_of_applications.sns.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'SNS'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">Twitter</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        @keypress="preventInputE"
                                                        class="form-control"
                                                        @input="inputNumber('number_of_applications', 'twitter')"
                                                        v-model="form.number_of_applications.twitter"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_applications.twitter.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.number_of_applications.twitter.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.number_of_applications.twitter.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'Twitter'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">Instagram</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        @keypress="preventInputE"
                                                        class="form-control"
                                                        @input="inputNumber('number_of_applications', 'instagram')"
                                                        v-model="form.number_of_applications.instagram"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_applications.instagram.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.number_of_applications.instagram.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.number_of_applications.instagram.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'Instagram'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">LINE</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        @keypress="preventInputE"
                                                        @input="inputNumber('number_of_applications', 'line')"
                                                        class="form-control"
                                                        v-model="form.number_of_applications.line"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_applications.line.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.number_of_applications.line.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.number_of_applications.line.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'LINE'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">その他</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        @keypress="preventInputE"
                                                        @input="inputNumber('number_of_applications', 'others')"
                                                        class="form-control"
                                                        v-model="form.number_of_applications.others"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_applications.others.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.number_of_applications.others.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.number_of_applications.others.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'その他'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">無効</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        @keypress="preventInputE"
                                                        @input="inputNumber('number_of_applications', 'invalid')"
                                                        class="form-control"
                                                        v-model="form.number_of_applications.invalid"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_applications.invalid.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.number_of_applications.invalid.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.number_of_applications.invalid.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: '無効'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">問い合わせ件数</label>
                                        <div class="col-sm-8">
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">総問い合わせ数</label>
                                                <div class="col-sm-5">
                                                    <input type="text" disabled class="form-control" v-model="form.number_of_inquiries.total" />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">TEL</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        @keypress="preventInputE"
                                                        @input="inputNumber('number_of_inquiries', 'tel')"
                                                        v-model="form.number_of_inquiries.tel"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_inquiries.tel.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.number_of_inquiries.tel.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.number_of_inquiries.tel.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'TEL'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">メール</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        @keypress="preventInputE"
                                                        @input="inputNumber('number_of_inquiries', 'email')"
                                                        v-model="form.number_of_inquiries.email"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_inquiries.email.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.number_of_inquiries.email.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.number_of_inquiries.email.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'メール'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">それ以外</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        @keypress="preventInputE"
                                                        @input="inputNumber('number_of_inquiries', 'number_of_cases')"
                                                        v-model="form.number_of_inquiries.number_of_cases"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_inquiries.number_of_cases.$error
                                                        }"
                                                    />
                                                    <div
                                                        v-if="submitted && $v.form.number_of_inquiries.number_of_cases.$error"
                                                        class="invalid-feedback"
                                                    >
                                                        <span v-if="!$v.form.number_of_inquiries.number_of_cases.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'それ以外'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">イレギュラー</label>
                                                <div class="col-sm-5">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        @keypress="preventInputE"
                                                        @input="inputNumber('number_of_inquiries', 'irregular')"
                                                        v-model="form.number_of_inquiries.irregular"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.number_of_inquiries.irregular.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.number_of_inquiries.irregular.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.number_of_inquiries.irregular.maxLength">{{
                                                            $t('validateField.max20', {
                                                                field: 'イレギュラー'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4 pr-0" for="validationCustom05">イレギュラー内容</label>
                                                <div class="col-sm-5">
                                                    <textarea
                                                        type="text"
                                                        class="form-control"
                                                        v-model="form.number_of_inquiries.irregular_content"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light btn-sm mr-3"
                            @click="
                                $router.push({
                                    path: '/prize/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            type="submit"
                            v-if="checkPermission('prize.register') || (checkPermission('prize.edit') && $route.query.id)"
                            class="btn btn-primary ml-3"
                            @click="formSubmit"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
