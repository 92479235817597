import i18n from '@/i18n';
import { getDateFromFullDate } from '@/utils/format';

function handleValidate(dataForm, configScheduledArrivedAt, configWarehousingDate, configShippingDate) {
    let check = true;
    if (dataForm.scheduled_arrived_at) {
        if (!getDateFromFullDate(dataForm.scheduled_arrived_at)) {
            check = false;
            configScheduledArrivedAt.error = true;
            configScheduledArrivedAt.errorText = i18n.t('validateField.incorrectFormat', {
                field: '入庫予定日'
            });
        }
    }

    if (dataForm.warehousing_date) {
        if (!getDateFromFullDate(dataForm.warehousing_date)) {
            check = false;
            configWarehousingDate.error = true;
            configWarehousingDate.errorText = i18n.t('validateField.incorrectFormat', {
                field: '入庫日'
            });
        }
    }

    if (dataForm.shipping_date) {
        if (!getDateFromFullDate(dataForm.shipping_date)) {
            check = false;
            configShippingDate.error = true;
            configShippingDate.errorText = i18n.t('validateField.incorrectFormat', {
                field: '入庫日'
            });
        }
    }

    return check;
}

export { handleValidate };
